export class Inertia{
  acc: number;
  friction: number;
  minV: number;
  maxV: number;
  bounce: number;
  #delta: number;
  public value: number;

  constructor(min:number, max:number, acceleration:number, friction:number, bounce:number){
    this.acc = acceleration;  
    this.friction = friction;
    this.minV = min;
    this.maxV = max;
    this.bounce = -Math.abs(bounce);
    this.#delta = 0;
    this.value = min;
  }
  update(input: number){
    this.#delta += (input - this.value) * this.acc;
    this.#delta *= this.friction;
    this.value += this.#delta;
    if (this.value < this.minV) {
       this.value = this.minV;
       if(this.#delta < 0){
           this.#delta *= this.bounce;
       }
    } else
    if (this.value > this.maxV) {
       this.value = this.maxV;
       if(this.#delta > 0){
           this.#delta *= this.bounce;
       }
    }
    return this.value;
  }
  setValue (input:number) {
    this.#delta = 0;
    this.value = Math.min(this.maxV, Math.min(this.minV, input));
    return this.value;
  }
}