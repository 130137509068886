import { WebGLRenderer, Audio, AudioAnalyser, DataTexture, AudioListener, RedFormat, LuminanceFormat } from "three";
import {GUI} from 'dat.gui';
import autoBind from 'auto-bind';
import audioPath1 from '../assets/music/sound1.mp3';
import audioPath2 from '../assets/music/sound2.mp3';
import audioPath3 from '../assets/music/sound4.mp3';
import audioPath4 from '../assets/music/sound3.mp3';
import { loadAudio } from "./helpers/loaders";
import { Inertia } from "./helpers/inertia";
import animate from './helpers/animate';

export interface SoundUniforms{
  tAudioData: {value: DataTexture},
  fqAvg: {value:number}
};

const events = ['update', 'mute', 'unmute', 'play', 'pause', 'stop', 'start', 'trackChanged'] as const;
type Events = typeof events[number];
class MusicPlayer{
  renderer: WebGLRenderer;
  pauseProgress = 0;
  audio: Audio<GainNode>;
  audioListener: AudioListener;
  audioAnalyser: AudioAnalyser;
  fftSize: 64;
  soundUniforms: SoundUniforms;
  audioFqAvg = 0;
  audioFqAvgInertia = new Inertia(0, 100, 0.4, 0.4, 0);
  isInit = false;
  startTime = null;
  activeIndex = 0;
  audioBuffers: AudioBuffer[] = [null,null,null,null];

  audioPrevTime = 0;
  audioCurrentTime = 0;
  muted = false;
  latestPositiveVolume = 1;
  eventsCallbacks: Record<Events, ((any)=>void)[] > = Object.fromEntries(events.map(event=>[event, []]));
  audioPaths = [
    audioPath1,
    audioPath2,
    audioPath3,
    audioPath4,
  ]
  get isPlaying(){
    return this.isInit && this.audio.isPlaying;
  }
  get currentTime(){
    return this.audioCurrentTime % this.audio.buffer.duration;
  }
  get audioProgress(){
    return this.currentTime / this.audio.buffer.duration;
  }

  constructor(){
    autoBind(this);
  }
  async init(renderer: WebGLRenderer, activeIndex:number = 0){
    this.audioListener = new AudioListener();
    this.audio = new Audio(this.audioListener);
    this.audioAnalyser = new AudioAnalyser(this.audio, this.fftSize);
    const format = ( renderer.capabilities.isWebGL2 ) ? RedFormat : LuminanceFormat;
    this.soundUniforms ={ 
      tAudioData: { 
        value: new DataTexture( this.audioAnalyser.data, this.fftSize / 2, 1, format ) 
      },
      fqAvg: {
        value: 0,
      }
    }
    this.isInit = true;
    this.activeIndex = activeIndex;
    await this.loadSound(activeIndex);
    this.setSound(activeIndex);
  }  
  async loadSound(index: number){
    if(this.audioBuffers[index]) {
      console.log('buffer exist: ',this.audioBuffers[index]);
      return;
    };
    const path = this.audioPaths[index];
    const audioBuffer = await loadAudio(path);
    this.audioBuffers[index] = audioBuffer;
  }
  setSound(index: number){
    this.activeIndex = index;
    const audioBuffer = this.audioBuffers[index];
    this.audio
      .setBuffer(audioBuffer)
      .setLoop(true);
    this.startTime = null; 
    this.emit('update', 0);
  }
  calcCurrentTime(){
    if(this.isPlaying){
      this.audioCurrentTime = this.audio.context.currentTime - this.audioPrevTime - this.startTime || 0;
    } else {
      this.audioPrevTime = this.audio.context.currentTime - this.startTime - this.audioCurrentTime;
    }
  }
  async play(){
    if(this.startTime === null){
      this.startTime = this.audio.context.currentTime;
      this.audioPrevTime = 0;
      this.emit('start');
    }else{
      this.emit('play');
    }
    this.audio.play();
    await this.animateVolumeTo(this.latestPositiveVolume);
  }
  async pause(){    
    this.emit('pause');
    await this.animateVolumeAndSave(0);
    this.audio.pause();
  }
  async stop(){
    const audioStopped = !this.audio.isPlaying && this.audio.context.currentTime === 0;
    if(audioStopped) return;
    this.emit('stop');
    await this.animateVolumeAndSave(0); 
    try{
      this.audio.stop();
    }catch(err){
      console.log(err);
    }
    this.startTime = null; 
  }
  async mute(){  
    this.muted = true;
    this.emit('mute');
    await this.animateVolumeAndSave(0);
  }
  async unmute(){
    this.muted = false;
    this.emit('unmute');
    await this.animateVolumeTo(this.latestPositiveVolume);
  }
  async animateVolumeAndSave(volume: number){
    if(this.muted && volume > 0) return;
    const currentVolume = this.audio.getVolume();
    await this.animateVolumeTo(volume);
    if(currentVolume > 0){
      // this.latestPositiveVolume = currentVolume;
    }
  }
  async animateVolumeTo(volume: number){
    if(this.muted && volume > 0) return;
    const currentVolume = this.audio.getVolume();
    const delta = currentVolume - volume;
    let promiseResolver: (value?: any)=>void;
    const promise = new Promise(r=>promiseResolver = r);
    animate(300, (p)=>{
      this.audio.setVolume(currentVolume - delta * p);
    }, promiseResolver);
    await promise;
  }
  async loadNext(){
    const nextIndex = (this.activeIndex + 1) % this.audioPaths.length;
    await this.switchSound(nextIndex)
  }
  async loadPrev(){
    let prevIndex = this.activeIndex - 1; 
    if(prevIndex < 0){
      prevIndex = this.audioPaths.length - 1
    }
    await this.switchSound(prevIndex);
  }
  async switchSound(index: number){ 
    await Promise.all([this.stop(), this.loadSound(index)]);
    this.emit('trackChanged', index);
    this.setSound(index);
  }
  on(event: Events, callback){
    this.eventsCallbacks[event].push(callback)
  }
  off(event: Events, callback){
    this.eventsCallbacks[event] = this.eventsCallbacks[event].filter(cb=>cb === callback)
  }
  emit(event: Events, ...args){
    this.eventsCallbacks[event].forEach(cb=>cb.apply(null, args));
  }
  onRender(){
    this.calcCurrentTime();
    if(this.isPlaying){
      this.emit('update', this.audioProgress);
    }
    if(this.isInit){
      this.audioAnalyser.getFrequencyData();
      this.audioFqAvg = this.audioAnalyser.getAverageFrequency();
      this.audioFqAvgInertia.update(this.audioFqAvg)
      this.soundUniforms.fqAvg.value = this.audioFqAvgInertia.value;
      this.soundUniforms.tAudioData.value.needsUpdate = true;
    }
  }
  setupGui(gui: GUI){
    const inertiaFolder = gui.addFolder('Inertia');
    inertiaFolder.open();
    inertiaFolder.add(this.audioFqAvgInertia, 'acc', 0, 1, 0.01 );
    inertiaFolder.add(this.audioFqAvgInertia, 'friction', 0, 1, 0.01 );
  }
}

export default MusicPlayer;