import autoBind from 'auto-bind';
import barba from '@barba/core';
import TinyUrl from 'tinyurl';

class Postcard{
  static baseUrl = 'https://iac-music.com/player?'
  constructor(){
    autoBind(this);
  }
  static addControls(form: HTMLFormElement, submitBtn: HTMLLinkElement){
    const handleSubmit = async ()=>{
      const hash = this.convertFormToHash(form);
      if(this.formValidation(form)){
        const shortUrlId = await this.getShortLink(hash);
        barba.go(`/player.html?postcard=${shortUrlId}`);
      } 
    }
    submitBtn.addEventListener('click', handleSubmit);
    return ()=>{
      submitBtn.removeEventListener('click', handleSubmit);
    }
  }
  static async getShortLink(hash:string){
    try{
      const shortenUrl = await fetch('https://api-ssl.bitly.com/v4/shorten',{
        method: "POST",
        body: JSON.stringify({
          "domain": "bit.ly",  
          "long_url": `${this.baseUrl}${hash}`  
        }),
        headers: {
          Authorization: 'Bearer 40c5895faf43104a0f5e77b0c59cf8c723d8137b',
          'Content-Type': 'application/json',
        }
      }).then(response => response.json())
      return shortenUrl.id.replace('bit.ly/', '');
    } catch(err){
      console.log(err);
    }
  }
  static async getFullLink(shortLinkId: string){ 
    const fullLink = await fetch(`https://api-ssl.bitly.com/v4/bitlinks/bit.ly/${shortLinkId}`,{
        headers: {
          Authorization: 'Bearer 40c5895faf43104a0f5e77b0c59cf8c723d8137b',
        }
      }).then(response => response.json())
      return fullLink.long_url ? fullLink.long_url.replace(this.baseUrl, '') : '';
  }
  static formValidation(form: HTMLFormElement){
    if(!form.elements.to.value) return
    if(!form.elements.from.value) return
    if(!form.elements.text.value) return
    return true;
  }
  static convertFormToHash(form: HTMLFormElement){
    const formData = new FormData(form);
    const object = {};
    formData.forEach((value, key) => object[key] = value);
    const hash = this.serialize(object);
    return hash;
  }
  static serialize(obj: any){
    return Object.keys(obj).map(function(k) {
      return encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]);
    }).join('&')
  }
  static deserialize(hash: string){
    const result = {};
    hash.split('&').forEach(part=>{
      const [key,value] = part.split('=').map(p=>decodeURIComponent(p));
      result[key] = value;
    })
    return result;
  }
}

export default Postcard;
