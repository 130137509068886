import barba from '@barba/core';
import Webgl from './webgl';
import gsap from 'gsap';
import CircleType from 'circletype';
import MobileDetect from 'mobile-detect'
import Postcard from './postcard';
import share from './helpers/share';
import { lang } from 'moment';

const mobileDetect = new MobileDetect(window.navigator.userAgent);

const webglContainer = document.querySelector('#webglContainer') as HTMLDivElement;
const {object} = Postcard.deserialize(window.location.search.slice(1));
const startObjectIndex = Number(object) || 0;
const limitedStartIndex = Math.max(Math.min(startObjectIndex, 3), 0)
const webglInstance = new Webgl(webglContainer, limitedStartIndex);

const applyPlayerControls = ()=>{
  clearPlayer = webglInstance.connectPlayerControls({
    mute: document.querySelector('#mute'),
    prevTrack: document.querySelector('#prevTrack'),
    playPause: document.querySelector('#playPause'),
    nextTrack: document.querySelector('#nextTrack'),
    progressLine: document.querySelector('#progressLine'), 
    trackName: document.querySelector('#trackName'), 
  }, [...document.querySelectorAll('.menu-button')]);
}
const applyPlayerSharings = ()=>{
  const copyBtn = document.querySelector('#w-node-ba61879d6bce-3fee9a7e');
  copyBtn.addEventListener('click',()=>{
    navigator.clipboard.writeText(window.location.href).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  })

  document.querySelector('#twShare')?.addEventListener('click',()=>share('tw'))
  document.querySelector('#fbShare')?.addEventListener('click', ()=>share('fb'))
}

const showPostcard = (from:string,to:string,text:string)=>{
  document.querySelector('.section').classList.add('postcard');
  document.querySelector('.lower-div').classList.add('postcard');
  
  document.getElementById('postcard-text')?.textContent = text;
  document.getElementById('postcard-from')?.textContent = from;
  document.getElementById('postcard-to')?.textContent = to;
  const readyPostcardContainer = document.querySelector('.ready-postcard');
  readyPostcardContainer?.style.display = 'block';
}
const showMakePostcard = ()=>{
  document.querySelector('.lower-div').classList.add('makePostcard');
}


const checkPostcard = async (search: string)=>{
  const query = search.slice(1);
  const {postcard} = Postcard.deserialize(query);
  console.log('postcard: ', postcard)
  if(postcard){
    try{
      const longUrlParams = await Postcard.getFullLink(postcard);
      const {from, to, text} = Postcard.deserialize(longUrlParams);
      console.log({from,to, text});
      if(from && to && text){
        showPostcard(from,to,text);
      } else{
        throw 'some arguments not found';
      }
    } catch(err){
      console.error(err)
      showMakePostcard();
    }
  } else{
    showMakePostcard();
  }
}

//TODO: удалить gsap
barba.init({
  transitions: [{
    name: 'page-transition',
    leave(data) {
      let resolver;
      const promise = new Promise<void>(r=>resolver = r);
      const tl = gsap.timeline()
      tl.to(data.current.container, {
        duration: .3,
        opacity: 0
      },'0')
      tl.then(resolver);
      return promise;
    },
    enter(data) {
      let resolver;
      const promise = new Promise<void>(r=>resolver = r);
      const tl = gsap.timeline();
      tl.from(data.current.container, {
        duration: .3,
        opacity: 0
      },'0')
      tl.then(resolver);
      if(window['ym']){
        window['ym'](70734037, 'hit', data.next.url.path);
      }
      if(window['gtag']){
        window['gtag']('config', 'G-H6LLSB31BL', {'page_path': data.next.url.path})
      }
      return promise;
    },
  }],
  views:[
    {
      namespace: 'player',
      afterEnter(){
        gsap.to(webglContainer, {duration: .3,autoAlpha: 1})
        applyPlayerControls();
        applyPlayerSharings();
        checkPostcard(window.location.search);
      },
      beforeLeave(){
        gsap.to(webglContainer, {duration:.3, autoAlpha:0})
      }
    },
    {
      namespace: 'home',
      afterEnter(){
        const preloader = document.getElementById('circleloader');
        if(preloader){
          new CircleType(preloader);
          preloader.style.opacity = '1';
        }
      }
    },
    {
      namespace: 'make-postcard',
      afterEnter(){
        const form = document.getElementById('email-form') as  HTMLFormElement;
        const submitBtn = document.getElementById('submit') as HTMLLinkElement;
        Postcard.addControls(form, submitBtn)
      }
    }
  ],
});
let clearPlayer: () => void = ()=>{}; 
barba.hooks.afterEnter((data)=>{
  const pageName = data.next.container.dataset.wfPage;
  document.documentElement.setAttribute('data-wf-page', pageName);
  window['Webflow'].destroy();
  window['Webflow'].ready();
  window['Webflow'].require( 'ix2' ).init();
  document.dispatchEvent( new Event( 'readystatechange' ) );
  setCurrentYear();
})
barba.hooks.beforeLeave(data=>{
  clearPlayer();
})
setLangLinkBehavior();


window.addEventListener('pageshow', (event) => {
  const  historyPage = event.persisted || ( typeof window.performance != "undefined" && window.performance.navigation.type === 2 );
  if ( historyPage ) {
    window.location.reload();
  }
});
window.addEventListener('popstate', function () {
  window.location.reload();
});

document.addEventListener("visibilitychange", ()=>{
  if(!document.hidden && (mobileDetect.mobile() || mobileDetect.tablet() || mobileDetect.phone())){
    window.location.reload();
  }
}, false);

function setCurrentYear(){
  const year = new Date().getFullYear()
  document.querySelectorAll('.js-currentYear').forEach(el=>el.innerHTML = String(year));
}
function setLangLinkBehavior(){
  const langLink = document.querySelector('.js-lang-link');
  langLink.addEventListener('click', e=>{
    e.preventDefault();
    const nextLinkOrigin = langLink.href.endsWith('/') ? langLink.href.slice(0,-1) : langLink.href;
    const {origin, href} = window.location;
    const nextLinkHref = href.replace(origin, nextLinkOrigin);
    window.location.href = nextLinkHref;
  })
}

