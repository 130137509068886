import { Group, Scene, Clock, ShaderMaterial, DoubleSide, Vector4, Vector2, Mesh, Material, Object3D, MeshMatcapMaterial, Texture, Points, Vector3, BufferGeometry, Float32BufferAttribute, BufferAttribute, AdditiveBlending, Color, MathUtils, MeshBasicMaterial, MeshLambertMaterial, MeshPhongMaterial, Raycaster, MeshStandardMaterial, WebGLRenderer, DataTexture, Audio, AudioAnalyser, RedFormat, LuminanceFormat, AudioListener, Shader, PointsMaterial} from "three";
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import autoBind from 'auto-bind';
import {GUI} from 'dat.gui';
import {loadScene} from './helpers/loaders';
import animate from './helpers/animate';

import { SoundUniforms } from "./musicPlayer";

type vertexShaderType = 'waves' | 'solid';

interface ModelShaders{
  wavesFrag: string;
  solidFrag: string;
  vertex: string;
}
interface ModelColors{
  transfusion: {
    color: string,
    intensity: number,
  }[],
  active: {
    from: string,
    to: string,
  }
}

export default class Model{
  name: string;
  modelPath: string;
  shadersPaths: ModelShaders;
  surfaces: Points<BufferGeometry, ShaderMaterial | PointsMaterial>[] = [];
  meshes: Mesh<BufferGeometry, MeshStandardMaterial>[] = [];
  soundUniforms: SoundUniforms;
  showCanceler: ()=>void;
  hideCanceler: ()=>void;
  scene: Scene;
  modelGuiFolder: any;

  settings = {
    animatePosition: true,
    animateColor: true,
    colors:{
      transfusion:[
        {
          color: 'rgb(255,215,0)',
          intensity: 0.5,
        },
        {
          color: 'rgb(238,232,170)',
          intensity: 0.8,
        },
        {
          color: 'rgb(255, 255, 255)',
          intensity: 1
        }
      ],
      active:{
        from: 'rgb(50, 37, 222)',
        to: 'rgb(108,99,223))'
      }
    }
  }

  get isInit(){
    return this.surfaces.length && this.meshes.length;
  }

  constructor({name, modelPath, shadersPaths, scene, colors}:{name: string, modelPath: string, shadersPaths: ModelShaders, scene: Scene, colors: ModelColors}){
    this.name = name;
    this.modelPath = modelPath;
    this.shadersPaths = shadersPaths;
    this.scene = scene;
    this.settings.colors = colors;
    autoBind(this);
  }

  async load(){
    if(this.isInit) return;
    const gltf = await loadScene(this.modelPath);
    const {meshes, surfaces} = this.makeSurfaces(gltf);
    this.meshes = meshes;
    this.surfaces = surfaces;
  }

  addSound(soundUniforms: SoundUniforms){
    this.soundUniforms = soundUniforms;
    if(this.surfaces.length){
      this.surfaces.forEach(surface=>{
        const type = surface.name === 'surface-solid' ? 'solid' : 'waves'; 
        surface.material = this.makeMaterial(type, soundUniforms);
      })
    }
  }

  makeSurfaces(gltf: GLTF){
    const parent = gltf.scene.children[0];
    const surfaces: Points<BufferGeometry, ShaderMaterial>[] = [];
    const meshes: Mesh<BufferGeometry, MeshStandardMaterial>[] = [];
    parent.traverse((node : Mesh<BufferGeometry, MeshStandardMaterial>)=>{
      if(node.isMesh){
        const mesh = node.clone();
        mesh.rotation.copy(parent.rotation);
        mesh.position.copy(parent.position);
        mesh.matrixWorldNeedsUpdate = true;
        mesh.material = new MeshStandardMaterial({color: 0x000000, side: DoubleSide, transparent: true, opacity:0});
        meshes.push(mesh);
        const surface = this.makeSurfaceMesh(mesh);
        surface.rotation.copy(mesh.rotation);
        surface.position.copy(mesh.position);
        surface.matrixWorldNeedsUpdate = true;
        surfaces.push(surface);
      }
    })
    return {
      meshes,
      surfaces
    }
  }

  makeSurfaceMesh(mesh: Mesh){
    const type = mesh.name === 'solid' ? 'solid' : 'waves';
    const material = this.soundUniforms ? this.makeMaterial(type, this.soundUniforms) : new ShaderMaterial();
    const geometry = new BufferGeometry()
    const sampler = new MeshSurfaceSampler(mesh)
	    .setWeightAttribute( 'color' )
      .build();
    const number = type === 'solid' ? 30000 : 50000;
    const pointPos = new Float32Array(number * 3);
    const normals = new Float32Array(number * 3);
    const sizes = new Float32Array(number);

    for (let index = 0; index < number; index++) {
      const _position = new Vector3();
      const _normal = new Vector3();
      sampler.sample(_position, _normal);
      pointPos.set([_position.x,_position.y, _position.z], index * 3);
      
      normals.set([_normal.x, _normal.y, _normal.z], index * 3);
      sizes.set([Math.random()], index);
    }
    geometry.setAttribute('position', new  BufferAttribute(pointPos, 3)); 
    geometry.setAttribute('normal', new  BufferAttribute(normals, 3)); 
    geometry.setAttribute('size', new  BufferAttribute(sizes, 1)); 
    const surface = new Points(geometry, material);
    surface.rotation.copy(mesh.rotation)
    surface.matrixWorldNeedsUpdate = true;
    surface.name = `surface-${mesh.name}`;
    return surface;
  }

  makeMaterial(figureType:vertexShaderType, soundUniforms: SoundUniforms){
    const shaderByType: Record<vertexShaderType, string> = {
      waves: this.shadersPaths.wavesFrag,
      solid: this.shadersPaths.solidFrag,
    }
    const colorUniforms = {
      activeColorFrom: {value: new Color(this.settings.colors.active.from)},
      activeColorTo: {value: new Color(this.settings.colors.active.to)},
    };
    this.settings.colors.transfusion.forEach((color,index)=>{
      colorUniforms[`transfusionColor${index}`] = {value: new Color(color.color)};
      colorUniforms[`transfusionColorIntensity${index}`] = {value: color.intensity};
    });
    return new ShaderMaterial({
      extensions: {
        derivatives: true,
      },
      side: DoubleSide,
      uniforms: {
        time: { value: 0 },
        resolution: { value: new Vector4() },
        uvRate1: {
          value: new Vector2(1, 1)
        },
        hoverProgress: {
          value: 0,
        },
        animatePosition: {
          value: true
        },
        animateColor: {
          value: true,
        },
        opacity: {value: 0},
        ...colorUniforms,
        ...soundUniforms,
      },
      // wireframe: true,
      transparent: true,
      // blending: AdditiveBlending,
      // depthTest: false,
      depthWrite: false,
      vertexShader: this.shadersPaths.vertex,
      fragmentShader: shaderByType[figureType],
    });
  }

  async show(){
    let resolver: ()=>void;
    const animationComplete = new Promise(r=>resolver=r);
    const meshes = [...this.meshes, ...this.surfaces];;
    meshes.forEach(m=>this.scene.add(m));
    const materials = meshes.map(m=>m.material);
    
    animate(300, p=>materials.forEach(m=>{
      if(m instanceof ShaderMaterial){
        m.uniforms.opacity.value = p;
      } else{
        m.opacity = p
      }
    }), resolver);
    await animationComplete;
  }

  async hide(){
    let resolver: ()=>void;
    const animationComplete = new Promise(r=>resolver=r);
    const meshes = [...this.meshes, ...this.surfaces];
    const materials = meshes.map(m=>m.material);
    animate(500, p=>materials.forEach(m=>{
      const alpha = 1 - p;
      if(m instanceof ShaderMaterial){
        m.uniforms.opacity.value = alpha;
      } else{
        m.opacity = alpha
      }
    }), resolver);
    await animationComplete;
    meshes.forEach(m=>this.scene.remove(m));
  }

  setupGui(gui: GUI){
    this.modelGuiFolder = gui.addFolder(`${this.name}`);
    this.modelGuiFolder.add(this.settings, 'animatePosition').onChange(animate=>{
      this.surfaces.forEach(surface=>{
        surface.material.uniforms.animatePosition.value = animate;
      })
    })
    this.modelGuiFolder.add(this.settings, 'animateColor').onChange(animate=>{
      this.surfaces.forEach(surface=>{
        surface.material.uniforms.animateColor.value = animate;
      })
    })

    const colorFolder = this.modelGuiFolder.addFolder('Colors');
    colorFolder.open();
    this.settings.colors.transfusion.forEach((color,index)=>{
      this.addGuiColor(colorFolder, color, 'Color', index);
    })
    colorFolder.addColor(this.settings.colors.active, 'from').listen().name('Active from').onChange(color=>{
      this.surfaces.forEach(surface=>{
        surface.material.uniforms.activeColorFrom.value = new Color(color);
      })
    });
    colorFolder.addColor(this.settings.colors.active, 'to').listen().name('Active to').onChange(color=>{
      this.surfaces.forEach(surface=>{
        surface.material.uniforms.activeColorTo.value = new Color(color);
      })
    });
  }

  removeGui(gui: GUI){
    gui.removeFolder(this.modelGuiFolder);
  }
  
  addGuiColor(gui: GUI, color: {color: string, intensity: number}, name?: string, index?: number){
    gui.addColor(color, 'color').name(`${name} ${index}`).listen().onChange((color)=>{
      this.surfaces.forEach(surface=>{
        surface.material.uniforms[`transfusionColor${index}`].value = new Color(color);
      })
    });
    gui.add(color, 'intensity',  0, 1, 0.1).listen().name(`Intensity ${index}`).onChange(intensity=>{
      this.surfaces.forEach(surface=>{
        surface.material.uniforms[`transfusionColorIntensity${index}`].value = intensity;
      })
    });
  }

  onRender(clock: Clock){
    if(this.isInit){
      const time = clock.getElapsedTime();
      this.surfaces.forEach(surface=>{
        if(surface.material.uniforms){
          surface.material.uniforms.time.value = time;
        }
      })
    }
  }

}
