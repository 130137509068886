import { Clock, Scene } from "three";
import { SoundUniforms } from "./musicPlayer";
import autoBind = require("auto-bind");
import {GUI} from 'dat.gui';

//MODELS
import carModelPath from '../assets/models/car.glb';
import manModelPath from '../assets/models/man.glb';
import sleightModelPath from '../assets/models/sleight.glb';
import treeModelPath from '../assets/models/tree.glb';

//CAR SHADERS
import carWavesFragPath from '../assets/shaders/car/wavesPollen.frag';
import carSolidFragPath from '../assets/shaders/car/solidPollen.frag';
import carVertPath from '../assets/shaders/car/pollen.vert';

//MAN SHADERS
import manWavesFragPath from '../assets/shaders/man/wavesPollen.frag';
import manSolidFragPath from '../assets/shaders/man/solidPollen.frag';
import manVertPath from '../assets/shaders/man/pollen.vert';

//SLEIGHT SHADERS
import sleightWavesFragPath from '../assets/shaders/sleight/wavesPollen.frag';
import sleightSolidFragPath from '../assets/shaders/sleight/solidPollen.frag';
import sleightVertPath from '../assets/shaders/sleight/pollen.vert';

//TREE SHADERS
import treeWavesFragPath from '../assets/shaders/tree/wavesPollen.frag';
import treeSolidFragPath from '../assets/shaders/tree/solidPollen.frag';
import treeVertPath from '../assets/shaders/tree/pollen.vert';
import Model from "./model";




export default class ModelController{
  soundUniforms: SoundUniforms;
  scene: Scene;
  models: Model[];
  activeModelIndex = 0;
  gui: GUI; 
  get activeModel(){
    return this.models[this.activeModelIndex];
  }

  constructor(){
    autoBind(this);
  }

  async load(scene: Scene, gui: GUI, activeIndex: number = 0){
    this.scene = scene;
    this.gui = gui;
    const carModel = new Model({
      name: 'Car',
      modelPath: carModelPath,
      shadersPaths: {
        wavesFrag: carWavesFragPath,
        solidFrag: carSolidFragPath,
        vertex: carVertPath,
      },
      colors: {
        transfusion:[
          {
            color: 'rgb(255, 255, 255)',
            intensity: 0.7,
          },
          {
            color: 'rgb(68, 183, 227)',
            intensity: 0.8,
          },
          {
            color: 'rgb(255, 255, 255)',
            intensity: 1
          }
        ],
        active:{
          from: 'rgb(124, 212, 255)',
          to: 'rgb(99, 223, 223))'
        }
      },  
      scene
    })
    const treeModel = new Model({
      name: 'Tree',
      modelPath: treeModelPath,
      shadersPaths: {
        wavesFrag: treeWavesFragPath,
        solidFrag: treeSolidFragPath,
        vertex: treeVertPath,
      },
      colors: {
        transfusion:[
          {
            color: 'rgb(0, 122, 0)',
            intensity: 0.5,
          },
          {
            color: 'rgb(56, 198, 73)',
            intensity: 0.8,
          },
          {
            color: 'rgb(255, 255, 255)',
            intensity: 1
          }
        ],
        active:{
          from: 'rgb(255, 255, 79)',
          to: 'rgb(251, 255, 168))'
        }
      },  
      scene
    })
    const sleightModel = new Model({
      name: 'Sleight',
      modelPath: sleightModelPath,
      shadersPaths: {
        wavesFrag: sleightWavesFragPath,
        solidFrag: sleightSolidFragPath,
        vertex: treeVertPath,
      },
      colors: {
        transfusion:[
          {
            color: 'rgb(229, 40, 0)',
            intensity: 0.5,
          },
          {
            color: 'rgb(235, 31, 91)',
            intensity: 0.8,
          },
          {
            color: 'rgb(255, 255, 255)',
            intensity: 1
          }
        ],
        active:{
          from: 'rgb(213, 7, 38)',
          to: 'rgb(227, 116, 55))'
        }
      },  
      scene
    })
    const manModel = new Model({
      name: 'Man',
      modelPath: manModelPath,
      shadersPaths: {
        wavesFrag: manWavesFragPath,
        solidFrag: manSolidFragPath,
        vertex: manVertPath,
      },
      colors: {
        transfusion:[
          {
            color: 'rgb(255,215,0)',
            intensity: 0.5,
          },
          {
            color: 'rgb(238,232,170)',
            intensity: 0.8,
          },
          {
            color: 'rgb(255, 255, 255)',
            intensity: 1
          }
        ],
        active:{
          from: 'rgb(50, 37, 222)',
          to: 'rgb(108,99,223))'
        }
      },  
      scene
    })
    this.models = [
      treeModel,
      manModel,
      carModel,
      sleightModel,
    ]
    this.activeModelIndex = activeIndex;
    await this.models[this.activeModelIndex].load();
  }

  async addSound(soundsUniforms: SoundUniforms){
    this.models.forEach(model=>model.addSound(soundsUniforms)); 
  }

  async showModel(index: number){
    const model = this.models[index]
    await model.show();
  }

  async hideModel(index: number){
    const model = this.models[index]
    await model.hide();
  }

  async loadNext(){
    const nextIndex = (this.activeModelIndex + 1) % this.models.length;
    await this.switchModel(nextIndex);
  }
  async loadPrev(){
    let prevIndex = this.activeModelIndex - 1; 
    if(prevIndex < 0){
      prevIndex = this.models.length - 1;
    }
    await this.switchModel(prevIndex);

  }
  async switchModel(index: number){
    const newModel = this.models[index];
    await Promise.all([this.hideModel(this.activeModelIndex), newModel.load()]);
    this.activeModelIndex = index;
  }
  async show(){
    await this.showModel(this.activeModelIndex)
  }
    
  onResize(w:number,h:number){
    
  }

  onRender(clock: Clock){
    this.activeModel.onRender(clock)
  }

  
}