const animate = (
  duration: number,
  onUpdate: (p: number) => void,
  onComplete = () => {},
  ease: (p: number) => number = (p) => p
) => {
  const start = performance.now();
  let stop = false;
  const animateFrame = (time: number) => {
      if (stop) return;
      let timeFraction = (time - start) / duration;
      if (timeFraction > 1) timeFraction = 1;
      const progress = ease(timeFraction);
      onUpdate(progress);
      if (timeFraction < 1) {
          requestAnimationFrame(animateFrame);
      } else {
          onComplete();
      }
  };
  requestAnimationFrame(animateFrame);
  return () => {
      stop = true;
  };
};

export default animate;
