import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import {TextureLoader, Texture, Object3D, Mesh, AudioLoader} from 'three';

const gltfLoader = new GLTFLoader();
const textureLoader = new TextureLoader();
const audioLoader = new AudioLoader();

export function loadScene(path): Promise<GLTF>{
  return new Promise((resolve,reject)=>{
    gltfLoader.load(path, function ( gltf ) {
      console.log(gltf)
      resolve(gltf);
    }, function ( xhr ) {
      console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
    }, function ( error ) {
      console.error( error );
      reject();
    } );
  })
}
export function loadTexture(path): Promise<Texture>{
  return new Promise((resolve,reject)=>{
    textureLoader.load(path, function ( texture ) {
      resolve(texture);
    }, function ( xhr ) {
      console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
    }, function ( error ) {
      console.error( error );
      reject();
    } );
  })
}
export function loadAudio(path): Promise<AudioBuffer>{
  return new Promise((resolve,reject)=>{
    audioLoader.load(path, function ( buffer ) {
      resolve(buffer);
    }, function ( xhr ) {
      console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
    }, function ( error ) {
      console.error( error );
      reject();
    } );
  })
}