
type Socials = 'fb' | 'tw' | 'pn'

const socialLinks: Record<Socials, string> = {
  fb: 'https://www.facebook.com/sharer/sharer.php',
  tw: 'https://twitter.com/intent/tweet',
  pn: 'http://pinterest.com/pin/create/button',
}


export default function share(socialKey: Socials) {
  const href = socialLinks[socialKey];
  const getTwTitle = (pageTitle:string) =>
    encodeURIComponent(
      `${pageTitle.slice(0, 190)}${
        pageTitle.length > 190 ? '...' : ''
      }`
    )
  const getImage = () => {
    const ogImage = document.querySelector("meta[property='og:image']")
    const imagePath = ogImage ? ogImage.content : ''
    return encodeURIComponent(imagePath)
  }
  const getTitle = () => {
    const ogTitleEl = document.querySelector("meta[property='og:title']")
    const ogTitle = ogTitleEl ? ogTitleEl.content : ''
    return ogTitle
  }
  const title = getTitle()
  let location = encodeURIComponent(window.location.href)
  if (location[location.length - 1] !== '/') {
    location += '/'
  }
  const twPost = getTwTitle(title)
  const image = getImage()
  let query = ''
  switch (socialKey) {
    case 'fb':
      query = `?u=${location}`
      break
    case 'tw':
      query = `?text=${twPost}&url=${location}`
      break
    case 'pn':
      query = `?url=${location}&description=${title}&media=${image}`
      break
    // case 'email':
    //   query = `?subject=View Lot via Shapiro Auctions&body=Check out lot ${title} ${location} on https://www.shapiroauctions.com/.`
    //   break
    default:
      break
  }
  window.open(href + query, '_blank')
}