import {WebGLRenderer, Scene, PerspectiveCamera, CameraHelper, Color, sRGBEncoding, PCFSoftShadowMap, Clock, BoxBufferGeometry, MeshPhongMaterial, Mesh, AxesHelper, Light, PlaneBufferGeometry, MathUtils, MeshBasicMaterial, Fog, AudioListener, Audio, Raycaster} from 'three';
import barba from '@barba/core';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import autoBind from 'auto-bind';
import {GUI} from 'dat.gui';
import MusicPlayer from './musicPlayer';
import ModelController from './modelController';

const songsTitles = [
  'Музыка на основе кода для банка',
  'Музыка на основе кода для продуктового ритейлера',
  'Музыка на основе кода для автоконцерна',
  'Музыка на основе кода для спортивного ритейлера',
]

export default class App{
  container: HTMLDivElement;
  renderer: WebGLRenderer;
  scene: Scene;
  camera: PerspectiveCamera;
  cameraHelper: CameraHelper;
  orbitControl: OrbitControls;
  clock: Clock;
  stats: Stats;
  cube: Mesh;
  modelController = new ModelController();
  logCameraPosition = false;
  listener: AudioListener;
  raycaster =  new Raycaster();
  mouse = {x:0, y:0};
  musicPlayer = new MusicPlayer();
  gui = new GUI();
  activeIndex = 0;
  objectsCount = 4;
  paginations: Element[];
  playerControls: Record<string, HTMLDivElement>;
  constructor(container: HTMLDivElement, activeIndex = 0){
    this.activeIndex = activeIndex;
    this.container = container;
    autoBind(this);
    (async ()=>{
      this.initThree();
      this.gui.destroy();
      await Promise.all([this.modelController.load(this.scene, this.gui, this.activeIndex), this.musicPlayer.init(this.renderer, this.activeIndex)]);      
      this.modelController.addSound(this.musicPlayer.soundUniforms);
      this.modelController.showModel(this.activeIndex);
      this.setupSceneHelpers();
      this.setupMouse();
      this.addCube();
      this.render();
      
      window.addEventListener('resize', this.onResize);
    })()  
  }
  initThree(){
    this.scene = new Scene();
    // this.scene.background = new Color(0x000000);

    this.camera = new PerspectiveCamera(45, this.container.offsetWidth / this.container.offsetHeight, 1, 1000);
    this.camera.position.set(7, 40, 80);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext( 'webgl', { alpha: false } );

    this.renderer = new WebGLRenderer( { canvas, context, antialias: true } );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( this.container.offsetWidth, this.container.offsetHeight );
    this.renderer.outputEncoding = sRGBEncoding;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = PCFSoftShadowMap;

    this.container.appendChild(this.renderer.domElement);
  }
  onResize(){
    this.updateCamera();
    this.renderer.setSize( this.container.offsetWidth, this.container.offsetHeight );
  }
  updateCamera(){
    this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;
    this.camera.updateProjectionMatrix();
  }
  setupSceneHelpers(){
    this.orbitControl = new OrbitControls(this.camera, this.renderer.domElement); 
    // this.orbitControl = new OrbitControls(this.camera, document.body); 
    this.orbitControl.enablePan = false;
    this.orbitControl.dampingFactor = 0.7;
    this.orbitControl.maxDistance = 200;
    this.orbitControl.minDistance = 70;
    this.clock = new Clock();
    this.stats = new Stats();
    // const axesHelper = new AxesHelper( 20 );
    // this.scene.add(axesHelper);
    // document.body.appendChild(this.stats.dom);
  }
  addCube(){
    const cubeSize = 1;
    const geometry = new BoxBufferGeometry(cubeSize, cubeSize, cubeSize);
    const material = new MeshPhongMaterial({color: '#ff00ff'});
    const mesh = new Mesh(geometry, material);
    mesh.castShadow = true
    this.cube = mesh;
    this.scene.add(mesh);
  }
  addPlane(){
    const planeSize = 300;
    const repeats = planeSize / 2;
    const planeGeo = new PlaneBufferGeometry(planeSize, planeSize);
    const planeMat = new MeshPhongMaterial({color: '#377371'})
    const mesh = new Mesh(planeGeo, planeMat);
    mesh.receiveShadow = true;
    mesh.rotation.x = MathUtils.degToRad(-90)
    this.scene.add(mesh)
  }
  addFog(){
    this.scene.fog = new Fog(this.scene.background as Color, 93, 177);
  }
  connectPlayerControls(controls: Record<string, HTMLDivElement>, paginationButtons: Element[]){
    this.playerControls = controls;
    this.paginations = paginationButtons;
    controls.playPause.addEventListener('click', ()=>{
      if(controls.playPause.hasAttribute('disabled')) return;
      if(this.musicPlayer.isPlaying){
        this.musicPlayer.pause();
      } else {
        this.musicPlayer.play();
      }
    });
    controls.nextTrack.addEventListener('click', ()=>{
      if(controls.nextTrack.hasAttribute('disabled')) return;
      const nextIndex = (this.activeIndex + 1) % this.objectsCount;
      this.switchModel(nextIndex);
    })
    controls.prevTrack.addEventListener('click', ()=>{
      if(controls.prevTrack.hasAttribute('disabled')) return;
      let prevIndex = this.activeIndex - 1; 
      if(prevIndex < 0){
        prevIndex = this.objectsCount - 1;
      }
      this.switchModel(prevIndex);
    })
    controls.mute.addEventListener('click', ()=>{
      if(controls.mute.hasAttribute('disabled')) return;
      if(this.musicPlayer.muted){
        this.musicPlayer.unmute();
      } else {
        this.musicPlayer.mute();
      }
    })
    paginationButtons.forEach((btn,index)=>{
      btn.addEventListener('click', ()=>{
        if(btn.hasAttribute('disabled')) return;
        this.switchModel(index)
      });
    })

    let prevProgres = 0;
    
    const onUpdate = (p: number)=>{
      const progress = Math.floor(p * 1000) / 10;      
      if(progress !== prevProgres){
        prevProgres = progress;
        controls.progressLine.style.transform = `translateX(${progress }%)`;
      }
    }
    const onPlay = ()=>{
      controls.playPause.classList.add('-play')
    }
    const onPause = ()=>{
      controls.playPause.classList.remove('-play')
    }
    const onMute = ()=>{
      controls.mute.classList.add('-mute')
    }
    const onUnmute = ()=>{
      controls.mute.classList.remove('-mute')
    }
    const onTrackChanged = (index: number) =>{
      this.activatePagination(index);
      this.changeTitle(index);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set('object', String(index));
      history.replaceState(null, null, "?"+queryParams.toString());
    }
    onTrackChanged(this.activeIndex);

    this.musicPlayer.on('update', onUpdate);
    this.musicPlayer.on('start', onPlay);
    this.musicPlayer.on('play', onPlay);
    this.musicPlayer.on('pause', onPause);
    this.musicPlayer.on('stop', onPause);
    this.musicPlayer.on('mute', onMute);
    this.musicPlayer.on('unmute', onUnmute);
    this.musicPlayer.on('trackChanged', onTrackChanged);

    return ()=>{
      this.musicPlayer.stop();
      this.musicPlayer.off('update', onUpdate);
      this.musicPlayer.off('play', onPlay);
      this.musicPlayer.off('pause', onPause);
      this.musicPlayer.off('stop', onPause);
      this.musicPlayer.off('mute', onMute);
      this.musicPlayer.off('unmute', onUnmute);
      this.musicPlayer.on('trackChanged', onTrackChanged);
    }
  }
  activatePagination(index:number){
    this.paginations.forEach(e=>e.classList.remove('highlighted'));
    this.paginations[index].classList.add('highlighted')
  }
  changeTitle(index: number){
    this.playerControls.trackName.innerHTML = songsTitles[index];
  }
  async switchModel(index: number){
    this.activeIndex = index;
    const isPlaying = this.musicPlayer.isPlaying;
    const controls = [...Object.values(this.playerControls),...this.paginations];
    controls.forEach(c=>c.setAttribute('disabled','disable')))
    await Promise.all([this.musicPlayer.switchSound(index), this.modelController.switchModel(index)]);
    this.orbitControl.reset();
    this.modelController.showModel(index)
    controls.forEach(c=>c.removeAttribute('disabled'))
    if(isPlaying){
      this.musicPlayer.play();
    }
  }
  setupGUI(){
    this.musicPlayer.setupGui(this.gui);
    this.gui.add({['Log_Camera_Position']: false},'Log_Camera_Position').onChange(log=>this.logCameraPosition = log)
    if(this.scene.fog){
      const fogFolder = this.gui.addFolder('Fog');    
      fogFolder.add(this.scene.fog, 'far', 100, 500, 1);
      fogFolder.add(this.scene.fog, 'near', 0, 300, 1);
    }
  }
  setupMouse(){
    window.addEventListener('mousemove', e=>{
      this.mouse.x = ( e.clientX / this.container.offsetWidth ) * 2 - 1;
      this.mouse.y = - ( e.clientY / this.container.offsetHeight ) * 2 + 1;
    })
  }
  render(){
    this.raycaster.setFromCamera(this.mouse, this.camera);
    if(this.cube){
      this.cube.rotation.x += 0.01;
      this.cube.rotation.y += 0.01;
    }
    if(this.logCameraPosition){
      console.log(this.camera.position);
    }
    this.modelController.onRender(this.clock);
    this.musicPlayer.onRender();
    this.camera.updateProjectionMatrix();
    this.stats.update();
    this.orbitControl.update();
    this.renderer.render( this.scene, this.camera );
    requestAnimationFrame(this.render);
  }
}